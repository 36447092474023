<template>
  <el-dialog
    title="订单详情"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="60%"
  >
    <el-form
      :model="order"
      ref="order"
      label-width="100px"
      style="display: flex; width: 100%; flex-wrap: wrap"
    >
      <el-form-item label="订单号" prop="id">
        <el-input
          disabled
          v-model="order.id"
          placeholder="订单号"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="司机" prop="driverUid">
        <el-select
          v-model="order.driverUid"
          filterable
          placeholder="选择司机"
          style="width: 320px"
        >
          <el-option
            v-for="item in userList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          style="margin-left: 10px"
          @click="updateDriver"
          >修改</el-button
        >
      </el-form-item>
      <el-form-item label="车主姓名" prop="userName">
        <el-input
          disabled
          v-model="order.userName"
          placeholder="车主姓名"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="userPhone">
        <el-input
          disabled
          v-model="order.userPhone"
          placeholder="联系电话"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品价格" prop="money">
        <el-input
          disabled
          v-model="order.money"
          placeholder="商品价格"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务费" prop="fuwufeiMoney">
        <el-input
          disabled
          v-model="order.fuwufeiMoney"
          placeholder="服务费"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="维修费" prop="weixiuMoney">
        <el-input
          disabled
          v-model="order.weixiuMoney"
          placeholder="维修费"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单状态" prop="status">
        <el-input
          disabled
          v-model="order.status"
          placeholder="订单状态"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="车牌号" prop="vname">
        <el-input
          disabled
          v-model="order.vname"
          placeholder="车牌号"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="接车时间" prop="jiecheDate">
        <el-input
          disabled
          v-model="order.jiecheDate"
          placeholder="接车时间"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="接车地点" prop="jiecheAddress">
        <el-input
          disabled
          v-model="order.jiecheAddress"
          placeholder="接车地点"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="检测站位置">
        <el-input
          disabled
          v-model="business.address"
          placeholder="检测站位置"
          style="width: 400px"
        ></el-input>
      </el-form-item>
      <el-form-item label="还车地点" prop="huancheAddress">
        <el-input
          disabled
          v-model="order.huancheAddress"
          placeholder="还车地点"
          style="width: 400px"
        ></el-input>
      </el-form-item>
    </el-form>

    <div>
      <driver-map ref="driverMap"></driver-map>
    </div>
    <div>
      <br />订单记录<br />
      {{ order.orderRecord }}
    </div>
    <div>
      <br />接车照片<br />
      <img
        v-for="item in jiechePics"
        style="width: 30%; margin-right: 5px"
        :key="item"
        :src="'https://www.jsfgx.online/newcar/newcar/common/pic/' + item"
        alt=""
      />
    </div>
    <div>
      <br />还车照片<br />
      <img
        style="width: 30%; margin-right: 5px"
        v-for="item in huanchePics"
        :key="item"
        :src="'https://www.jsfgx.online/newcar/newcar/common/pic/' + item"
        alt=""
      />
    </div>
    <div>
      <br />年检报告照片<br />
      <img
        style="width: 30%; margin-right: 5px"
        v-for="item in baogaoPics"
        :key="item"
        :src="'https://www.jsfgx.online/newcar/newcar/common/pic/' + item"
        alt=""
      />
    </div>
  </el-dialog>
</template>

<script>
import DriverMap from "../../src/components/DriverMap";
import { getUserInfo, updateOrderDriver } from "../../api/api";
export default {
  components: {
    DriverMap,
  },
  data() {
    return {
      userList: [], // 司机列表
      visible: false,
      jiechePics: [],
      huanchePics: [],
      baogaoPics: [],
      driverInfo: {},
      order: {},
      business: {},
    };
  },
  methods: {
    // 修改订单司机
    updateDriver() {
      updateOrderDriver({
        id: this.order.id,
        cid: this.order.cid,
        driverUid: this.order.driverUid,
      }).then((data) => {
        if (data && data.code === 0) {
          this.$message.success(data.msg);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 初始化
    async init(order, driverLat, driverLon, business, driverList) {
      this.driverInfo = {};
      this.userList = driverList;
      // 获取司机信息，以获取位置
      let driverUid = order.driverUid;
      if (driverUid != 0) {
        let data = await getUserInfo({ id: driverUid });
        if (data && data.code === 0) {
          this.driverInfo = data.data;
        }
      }

      // 初始化回显数据
      this.visible = true;
      this.$nextTick(() => {
        this.order = order;
        if (driverUid != 0) {
          driverLat = this.driverInfo.lat;
          driverLon = this.driverInfo.lon;
        } else {
          driverLat = 0;
          driverLon = 0;
        }
        if (this.order.status == 0) {
          this.order.status = "待分配司机";
        } else if (this.order.status == 1) {
          this.order.status = "待确认价钱";
        } else if (this.order.status == 2) {
          this.order.status = "待支付";
        } else if (this.order.status == 3) {
          this.order.status = "已支付，待消费";
        } else if (this.order.status == 4) {
          this.order.status = "已消费";
        } else if (this.order.status == 5) {
          this.order.status = "申请退款中";
        } else if (this.order.status == 6) {
          this.order.status = "已退款";
        } else if (this.order.status == 7) {
          this.order.status = "已接车，正在前往检测";
        } else if (this.order.status == 8) {
          this.order.status = "检测中";
        } else if (this.order.status == 9) {
          this.order.status = "检测通过，正在前往还车";
        } else if (this.order.status == 10) {
          this.order.status = "检测不通过";
        } else if (this.order.status == 11) {
          this.order.status = "检测不通过，客户选择终止";
        } else if (this.order.status == 12) {
          this.order.status = "维修中";
        } else if (this.order.status == 13) {
          this.order.status = "已维修，重新年检";
        } else if (this.order.status == 14) {
          this.order.status = "已还车";
        } else if (this.order.status == 15) {
          this.order.status = "订单完成";
        }
        this.business = business;

        if (this.order.jiechePic && this.order.jiechePic.length > 0) {
          this.jiechePics = order.jiechePic.split("_");
        }
        if (this.order.jiechePic && this.order.huanchePic.length > 0) {
          this.huanchePics = order.huanchePic.split("_");
        }
        if (this.order.jiechePic && this.order.baogaoPic.length > 0) {
          this.baogaoPics = order.baogaoPic.split("_");
        }

        this.$refs.driverMap.init(
          this.order,
          driverLat,
          driverLon,
          order.jiecheLat,
          order.jiecheLon,
          order.huancheLat,
          order.huancheLon,
          order.business.lat,
          order.business.lon
        );
      });
    },
  },
};
</script>
