<template>
  <div>
    <div id="map"></div>
  </div>
</template>

<script>
export default {
  props: [
    "driverlat",
    "driverlon",
    "jiechelat",
    "jiechelon",
    "huanchelat",
    "huanchelon",
    "jiancezhanlat",
    "jiancezhanlon",
  ],
  data() {
    return {};
  },
  computed: {
    // 初始化地图
    map() {
      // 定义地图中心点坐标
      let center = new window.TMap.LatLng(22.826675305, 108.315303012);
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      let mapVal = new window.TMap.Map(document.getElementById("map"), {
        center: center, // 设置地图中心点坐标
        zoom: 12, // 设置地图缩放级别
        pitch: 20, // 设置俯仰角
        rotation: 45, // 设置地图旋转角度
      });
      return mapVal;
    },
    //初始化marker图层
    markerLayer() {
      var markerLayer = new window.TMap.MultiMarker({
        id: "marker-layer",
        map: this.map,
      });
      return markerLayer;
    },
  },
  methods: {
    init(
      order,
      driverlat,
      driverlon,
      jiechelat,
      jiechelon,
      huanchelat,
      huanchelon,
      jiancezhanlat,
      jiancezhanlon
    ) {
      // 展示司机位置
      this.showDriverLocation(
        driverlat,
        driverlon,
        jiancezhanlat,
        jiancezhanlon
      );

      if (order.status < 7) {
        this.getGuilu(driverlat, driverlon, jiechelat, jiechelon);
      } else if (order.status < 9) {
        this.getGuilu(jiechelat, jiechelon, jiancezhanlat, jiancezhanlon);
      } else {
        this.getGuilu(jiancezhanlat, jiancezhanlon, huanchelat, huanchelon);
      }
    },

    showDriverLocation(driverlat, driverlon, jiancezhanlat, jiancezhanlon) {
      // 改变地图中心点位置
      if (driverlat != 0 && driverlon != 0) {
        this.map.setCenter({ lat: driverlat, lng: driverlon });
      } else {
        this.map.setCenter({ lat: jiancezhanlat, lng: jiancezhanlon });
      }

      // 添加标记
      if (driverlat != 0 && driverlon != 0) {
        let latlng = {
          lat: driverlat,
          lng: driverlon,
          height: 0,
        };
        this.markerLayer.add({
          position: latlng,
        });
      }
    },

    getGuilu(startLat, startLon, endLat, endLon) {
      var startPosition = new window.TMap.LatLng(startLat, startLon); // 路线规划起点
      var endPosition = new window.TMap.LatLng(endLat, endLon); // 路线规划终点

      // 新建一个驾车路线规划类
      var driving = new window.TMap.service.Driving({
        mp: false, // 是否返回多方案
        policy: "PICKUP,NAV_POINT_FIRST", // 规划策略
      });

      driving
        .search({ from: startPosition, to: endPosition })
        .then((result) => {
          this.showLuxian(result.result.routes[0].polyline);
        });
    },

    // 显示路线
    showLuxian(paths) {
      //创建 MultiPolyline
      new window.TMap.MultiPolyline({
        id: "polyline-layer", //图层唯一标识
        map: this.map, //绘制到目标地图
        //折线样式定义
        styles: {
          style_blue: new window.TMap.PolylineStyle({
            color: "#3777FF", //线填充色
            width: 6, //折线宽度
            borderWidth: 5, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "butt", //线端头方式
          }),
        },
        //折线数据定义
        geometries: [
          {
            //第1条线
            id: "pl_1", //折线唯一标识，删除时使用
            styleId: "style_blue", //绑定样式名
            paths: paths,
          },
        ],
      });
    },
  },
  mounted() {
    console.log("mounted", this.markerLayer);

    // 改变地图中心点位置
    // this.map.setCenter({ lat: this.$parent.lat, lon: this.$parent.lon });
    // // 添加标记
    // this.markerLayer.add({
    //   position: { lat: this.$parent.dalat, lon: this.$parent.lon },
    // });
  },
};
</script>
<style>
#map {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}
</style>
