<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-input
          style="width: 180px"
          v-model="dataForm.keyword"
          placeholder="请输入关键字"
          clearable
        ></el-input>
      </el-form-item>
      <el-select
        v-model="dataForm.cid"
        clearable
        :placeholder="companyName ? companyName : '按代理商筛选'"
        filterable
        @focus="companySelectFocus"
        style="width: 180px; margin-right: 10px"
        @change="changeCompany"
      >
        <el-option
          v-for="item in companyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.bid"
        clearable
        placeholder="按商家筛选"
        filterable
        @focus="businessSelectFocus"
        style="width: 180px; margin-right: 10px"
      >
        <el-option
          v-for="item in businessOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        style="width: 180px; margin-right: 10px"
        v-model="dataForm.uid"
        filterable
        remote
        clearable
        reserve-keyword
        placeholder="按用户筛选"
        :remote-method="remoteManager"
        :loading="loading"
      >
        <el-option
          v-for="item in userList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.type"
        clearable
        placeholder="按类型筛选"
        style="width: 180px; margin-right: 10px"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataForm.status"
        clearable
        placeholder="按状态筛选"
        style="width: 180px; margin-right: 10px"
      >
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        style="width: 180px; margin-right: 10px"
        v-model="dataForm.startDate"
        align="right"
        type="date"
        placeholder="选择开始日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
        >
      </el-date-picker>
      <el-date-picker
        style="width: 180px; margin-right: 10px"
        v-model="dataForm.endDate"
        align="right"
        type="date"
        placeholder="选择开始日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
        >
      </el-date-picker>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <!-- <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="订单号"
        width="80px"
      >
      </el-table-column>
      <el-table-column
        prop="cname"
        header-align="center"
        align="center"
        label="所属代理商"
      >
      </el-table-column>
      <el-table-column
        prop="bname"
        header-align="center"
        align="center"
        label="所属商家"
      >
      </el-table-column>
      <el-table-column
        prop="pname"
        header-align="center"
        align="center"
        label="商品"
      >
      </el-table-column>
      <el-table-column
        prop="driverName"
        header-align="center"
        align="center"
        label="司机"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="订单类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">新车</span>
          <span v-if="scope.row.type == 1">二手车</span>
          <span v-if="scope.row.type == 2">洗护产品</span>
          <span v-if="scope.row.type == 3">加油站产品</span>
          <span v-if="scope.row.type == 4">车检产品</span>
          <span v-if="scope.row.type == 5">维修产品</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="用户名字"
      >
      </el-table-column>
      <el-table-column
        prop="userPhone"
        header-align="center"
        align="center"
        label="用户联系电话"
      >
      </el-table-column>
      <el-table-column
        prop="weixMoney"
        header-align="center"
        align="center"
        label="金额"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.weixMoney / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        prop="orderBody"
        header-align="center"
        align="center"
        label="订单内容"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="payType"
        header-align="center"
        align="center"
        label="支付类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.payType == 0">现金</span>
          <span v-if="scope.row.payType == 1">积分</span>
          <span v-if="scope.row.payType == 2">现金+积分</span>
        </template>
      </el-table-column> -->

      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">待分配司机</span>
          <span v-if="scope.row.status == 1">待确认价钱</span>
          <span v-if="scope.row.status == 2">待支付</span>
          <span v-if="scope.row.status == 3">已支付，待消费</span>
          <span v-if="scope.row.status == 4">已消费</span>
          <span v-if="scope.row.status == 5">申请退款中</span>
          <span v-if="scope.row.status == 6">已退款</span>
          <span v-if="scope.row.status == 7">已接车，正在前往检测</span>
          <span v-if="scope.row.status == 8">检测中</span>
          <span v-if="scope.row.status == 9">检测通过，正在前往还车</span>
          <span v-if="scope.row.status == 10">检测不通过</span>
          <span v-if="scope.row.status == 11">检测不通过，客户选择终止</span>
          <span v-if="scope.row.status == 12">维修中</span>
          <span v-if="scope.row.status == 13">已维修，重新年检</span>
          <span v-if="scope.row.status == 14">已还车</span>
          <span v-if="scope.row.status == 15">订单完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="备注"
      >
      </el-table-column>
      <el-table-column
        prop="updateLog"
        header-align="center"
        align="center"
        label="订单修改记录"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 5"
            type="text"
            size="small"
            @click="refundHandle(scope.row.id)"
            >处理退款</el-button
          >
          <el-button
            v-if="scope.row.type == 4 && scope.row.isDesignated == 1"
            type="text"
            size="small"
            @click="orderDetailHandle(scope.row, 0, 0, scope.row.business)"
            >查看</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <el-dialog title="退款审核" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="refundForm">
        <el-form-item label="处理" label-width="100px">
          <el-select
            v-model="refundForm.flag"
            placeholder="处理"
            style="width: 100%"
          >
            <el-option
              v-for="item in refundOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="systemSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>

    <!-- 弹窗,订单详情 -->
    <order-detail v-if="orderDetailVisible" ref="orderDetail"></order-detail>
  </div>
</template>

<script>
import {
  getOrderList,
  deleteOrderById,
  getCompanyList,
  getBusinessList,
  getUserList,
  refund,
} from "../../api/api";
import AddOrUpdate from "./order-add-or-update";
import OrderDetail from "./order-detail.vue";
export default {
  data() {
    return {
      dialogFormVisible: false,
      orderDetailVisible: false,
      refundForm: {
        id: "",
        flag: "",
      },
      refundOptions: [
        {
          value: 1,
          label: "同意退款",
        },
        {
          value: 0,
          label: "拒绝退款",
        },
      ],
      dataForm: {
        keyword: "",
        cid: "",
        bid: "",
        uid: "",
        type: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      // 状态选择器列表
      statusOptions: [
        {
          value: 0,
          label: "新建",
        },
        {
          value: 1,
          label: "待确认价钱",
        },
        {
          value: 2,
          label: "待支付",
        },
        {
          value: 3,
          label: "已支付，待消费",
        },
        {
          value: 4,
          label: "已消费",
        },
        {
          value: 5,
          label: "申请退款中",
        },
        {
          value: 6,
          label: "已退款",
        },
        {
          value: 7,
          label: "已接车，正在前往检测",
        },
        {
          value: 8,
          label: "检测中",
        },
        {
          value: 9,
          label: "检测通过，正在前往还车",
        },
        {
          value: 10,
          label: "检测不通过",
        },
        {
          value: 11,
          label: "检测不通过，客户选择终止",
        },
        {
          value: 12,
          label: "维修中",
        },
        {
          value: 13,
          label: "已维修，重新年检",
        },
        {
          value: 14,
          label: "已还车",
        },
        {
          value: 15,
          label: "订单完成",
        },
      ],
      // 类型选择器列表
      typeOptions: [
        {
          value: 0,
          label: "新车",
        },
        {
          value: 1,
          label: "二手车",
        },
        {
          value: 2,
          label: "洗养产品",
        },
        {
          value: 3,
          label: "加油站产品",
        },
        {
          value: 4,
          label: "车检产品",
        },
        {
          value: 5,
          label: "维修产品",
        },
      ],
      // 代理商选择器列表
      companyOptions: [],
      // 代理商选择器默认展示
      companyName: "",
      // 商家选择器列表
      businessOptions: [],
      loading: false,
      // 用户选择器列表
      userList: [],
      // 数据列表
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
    OrderDetail,
  },

  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo.cid) {
      this.dataForm.cid = userInfo.company.id;
      this.companyOptions.push({
        value: this.dataForm.cid,
        label: userInfo.company.name,
      });
      this.companyName = userInfo.company.name;
    }
    this.getDataList();
    this.getUserList();
  },
  methods: {
    // 获取司机列表
    getUserList() {
      this.userList = [];
      let requestData = { type: 2 };
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type == 0) {
        requestData.cid = "";
      } else {
        requestData.cid = this.dataForm.cid;
      }
      getUserList(requestData).then((data) => {
        if (data && data.code === 0) {
          for (let item of data.data.list) {
            let userOps = {};
            userOps.value = item.id;
            userOps.label =
              item.nickName +
              "(" +
              (item.realName ? item.realName : "未实名") +
              ")";
            this.userList.push(userOps);
          }
        } else {
          this.userList = [];
        }
      });
    },
    // 处理退款
    refundHandle(id) {
      this.dialogFormVisible = true;
      this.refundForm.id = id;
    },
    // 提交退款处理
    systemSubmit() {
      refund(this.refundForm).then((data) => {
        if (data.code == 0) {
          this.$message({
            message: data.msg,
            type: "success",
            duration: 1500,
            onClose: () => {
              this.dialogFormVisible = false;
              this.getDataList();
            },
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 搜索用户
    remoteManager(query) {
      if (query !== "") {
        this.loading = true;
        this.userList = [];
        setTimeout(() => {
          // 请求用户列表，封装数据
          let requestData = { keyword: query };
          getUserList(requestData).then((data) => {
            if (data && data.code === 0) {
              for (let item of data.data.list) {
                let userOps = {};
                userOps.value = item.id;
                userOps.label = item.nickName + "(" + item.realName + ")";
                this.userList.push(userOps);
              }
            } else {
              this.userList = [];
            }
          });
        }, 200);
        this.loading = false;
      }
    },
    // 选择代理商的事件
    changeCompany(value) {
      this.dataForm.bid = "";
      this.businessName = "";
      this.dataForm.cid = value;
    },
    // 商家选择框获得焦点事件
    businessSelectFocus() {
      if (this.dataForm.cid == "" || this.dataForm.cid == null) {
        this.$message.error("请先选择代理商");
        return;
      }
      if (this.businessOptions.length > 0) {
        return;
      }
      this.dataListLoading = true;
      getBusinessList({ limit: 99999, cid: this.dataForm.cid }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.businessOptions = [];
            for (let item of resultList) {
              this.businessOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 代理商选择框获得焦点事件
    companySelectFocus() {
      if (this.companyOptions.length > 0) {
        return;
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type == 1) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 订单详情
    orderDetailHandle(order, driverLat, driverLon, business) {
      this.orderDetailVisible = true;
      this.$nextTick(() => {
        let data = JSON.parse(JSON.stringify(order)); // 深拷贝
        data.money = data.money ? data.money / 100 : 0;
        data.weixiuMoney = data.weixiuMoney ? data.weixiuMoney / 100 : 0;
        data.fuwufeiMoney = data.fuwufeiMoney ? data.fuwufeiMoney / 100 : 0;
        this.$refs.orderDetail.init(
          data,
          driverLat,
          driverLon,
          business,
          this.userList
        );
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      getOrderList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteOrderById({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
